<template>
  <div class="three-d-model">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/3D Model.png" />
        <span>3D Model</span>
      </div>

      <el-button type="info" @click="$router.push('/model')">View 3D</el-button>
    </div>

    <div class="three-d-model__content _flex _flex-justify__center">
      <img src="@/assets/icons/dashboard/tower.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.three-d-model__content {
  > img {
    width: 47%;
  }
}
</style>
